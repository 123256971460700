.comment_tags_container {
  width: 300px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 3px;
}

.comment_tag {
  height: 18px;
  border-radius: 4px;
  padding: 3px 8px 0px 8px;
  font-weight: 700;
  font-size: 10px;
  line-height: 13.62px;
}

.cl_tags_textfield div input {
  width: 963px !important;
  background-color: #fff !important;
  border-color: "#E5E8E8" !important;
  height: 5px !important;
}

.cl_tags_text div input {
  width: 450px !important;
  background-color: #fff !important;
  border-color: "#E5E8E8" !important;
  height: 3px !important;
  font-family: "Open sans" !important;
}
